var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4", attrs: { height: "300" } },
        [
          _c("v-card-text", [
            _c(
              "div",
              [
                _c(
                  "v-alert",
                  {
                    attrs: {
                      border: "top",
                      "colored-border": "",
                      prominent: "",
                      elevation: "4",
                      color: "error darken-1"
                    }
                  },
                  [
                    _c("p", [
                      _vm._v("Please check your email and verify your account.")
                    ])
                  ]
                ),
                _c(
                  "v-btn",
                  { attrs: { large: "", color: "primary", to: "/" } },
                  [
                    _c("v-icon", { staticClass: "mr-2" }, [
                      _vm._v("mdi-account-check")
                    ]),
                    _vm._v("I have verifed my account ")
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }