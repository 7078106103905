<template>
    <div>
        <psi-page-title :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></psi-page-title>
        <!-- Content always starts with a v-card  
        -->
        <v-card class="mt-4" height="300">
            <v-card-text>
                <div>
                    <v-alert
                        border="top"
                        colored-border
                        prominent
                        elevation="4"
                        color="error darken-1"
                    >
                        <p>Please check your email and verify your account.</p>
                    </v-alert>
                    <v-btn large color="primary" to="/">
                        <v-icon class="mr-2">mdi-account-check</v-icon>I have verifed my account
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "verify-email",
    data() {
        return {
            page: {
                title: "Verify Email",
                icon: "mdi-account-check",
            },
            breadcrumbs: [
                {
                    text: "Verify Email",
                    disabled: true,
                },
            ],
        };
    },
    mounted() {},
};
</script>

<style scoped>
</style>